<template>
    <label class="flex justify-start my-4 cursor-pointer">
        <input @change="r => changeValue(r)" type="radio" class="mr-2" name="answer" :value="id" />
        {{ text }}
    </label>
</template>

<script>
export default {
    name: 'anserItem',
    props: ["id", "text"],
    methods: {
        changeValue(val) {
            this.$emit('change', val.target.value)
        }
    }
}
</script>