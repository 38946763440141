import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '../views/home.vue'
import kvkk from '../views/questions/kvkk.vue'

Vue.use(VueRouter)

const routes = [
 
  {
    path: '/',
    name: 'home',
    component: home,
  },
   
  {
    path: '/kvkk',
    name: 'kvkk',
    component:kvkk
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
