<template>
    <div class="flex justify-center items-center min-h-screen  relative">

        <img  class ="w-60 absolute xl:right-20 sm:right-4  xl:top-5 sm:top-0  object-contain" src="@/assets/logo-new.png" alt="">

        <generalItem v-if="step==1" @nextStep="step=2" />
        <questionsItem v-if="step==2" @nextStep="step=3" />
        <ratingItem v-if="step==3" @nextStep="step=4" />
        <thanksItem v-if="step==4" />
    </div>
</template>
<script>
import generalItem from './questions/general.vue';
import questionsItem from './questions/questions.vue';
import ratingItem from './questions/rating.vue';
import thanksItem from './questions/thanksItem.vue'
export default {
    name: 'homePage',
    components: {
        generalItem,
        questionsItem,
        ratingItem,
        thanksItem
    },
    data(){
        return {
            step:1,
        }
    }
}

</script>