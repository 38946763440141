<template>
   <div class="px-20 py-10">
        <a href="/"  type="submit" class="bg-[#a70431]  px-3 py-3  font-bold text-xs text-white rounded-md">
                Önceki Sayfaya Geri Dön
        </a>
        <p class="text-black mt-4 ">
        MEDYA ÇALIŞMALARI İZİN BELGESİ
        Adisseo Yem Katkıları Ltd. Şti ve iştiraklerine bağlı olarak kurulan web sitesi, broşür, kurumsal dergi,
        tanıtım sunumu ve sair alanlarda yayınlanmak üzere yapılması planlanan içeriklere muvafakatim olduğunu,
        bu kapsamda fotoğraf, video ve görüntülerimin alınmasına onay verdiğimi, hazırlanacak içeriğin kurumun
        web sitesi, sosyal medya hesaplarında duyurusu ile elektronik, görsel, işitsel ve yazılı medyaya yönelik
        haber çalışmalarında kullanılarak umuma iletimine muvafakat ettiğimi, söz konusu içerik ve kapsamında
        yer alan görüntü ve bilgiler nedeniyle Fikir ve Sanat Eserleri Kanununda ("FSEK") yerini bulan eser sahipligi
        ve/veya bağlantılı hak sahipliğini doğuran bir yaratım ortaya çıkması halinde bu yaratım üzerinde doğan
        FSEK'in 80. maddesinden kaynaklanan bağlantılı haklar da dahil olmak üzere 21, 22, 23, 24 ve
        25. maddelerinde tarif edilen haklardan işleme, yayma, çoğaltma, temsil, telli, telsiz her türlü araçla,
        internet ve sair elektronik ortamlarda ve radyo, televizyon ile işaret, ses ve/veya görüntü nakline yarayan
        diğer tüm araçlarla umuma iletimi haklarımın süre, yer ve içerik sınırlaması olmaksızın gerek yurt içi,
        gerekse yurt dışında (tüm dünya ülkeleri) kullanmak üzere münhasiran Adisseo Yem Katkıları Ltd. Şti ve
        iştirakleri tarandan kullanılmasına yasalardan doğan haklarımıza ilişkin olarak yetki verdiğimi bildirir
        (kendim ve/veya birincil derecede yakınım adına yetki verdiğimi bildirir) işbu belgenin imzasında herhangi
        bir maddi karşılık talep etmediğimiz gibi sonrasında da herhangi bir sebeple kullanım dolayısıyla bedel
        talep etmeyeceğimizi, işbu belgenin bir hak devir beyani, muvafakatname ve izin belgesi olduğunu beyan,
        kabul ve taahhüt ederim.
        Adisseo Yem Katkıları Ltd. Şti tarandan, 6698 sayili Kişisel Verilerin Korunması Kanunu ("KVKK")
        kapsamında işleme amaci ile bağlantılı, sınırlı ve ölçülü olacak şekilde talep edilen ve paylaşmış olduğunuz
        kişisel veriler, yukarıda yer alan amaç/amalar çerçevesinde; kaydedilecek, depolanacak, muhafaza edilecek,
        yeniden düzenlenecek, hukuken bu kişisel verileri talep etmeye yetkili olan kurumlar ile paylaşılacak,
        KVKK'nin ve diğer kanunların öngördüğü koşullarda grup şirketleri de dahil olmak üzere yurt içi veya yurt
        dışı üçüncü kişilere aktarılacak, devredilebilecek, sınıandırılabilecek ve KVKK'da sayılan diğer şekillerde
        işlenebilecektir. KVKK Made Il'de yer alan haklarınızı kullanmak amacıyla Adisseo Yem Katkıları
        Ltd. Şti ’ne yazılı olarak başvuruda bulunabilirsiniz. Kişisel verilerimin yukarıda izah edildiği şekilde
        işlenmesine, aktarılmasına özgür irademle Okudum, anladım, izin veriyorum.
      
    
    </p>
</div>
    
</template>

<script>
export default {
    name: 'kvkkPage',
}
</script>