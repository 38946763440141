<template>
    <form @submit.prevent="save" class="bg-gray-200 px-16  py-10 min-h-screen w-full  " align="center">
        <h2 class="text-xl text-left  w-ful font-bold w-8/12 pr-4 ">Adisseo’nun Türkiye’deki ürün ve Hizmetleri hakkındaki görüşünüzü
            1-10 arasında puanlayınız?</h2>

        <div class="grid grid-cols-10 gap-5   mt-10">

            <button type="button" 
            :class="item==rating && 'text-[#a70431]'"
            @click="rating = item" v-for="item in 10" :key="item" class=" mt-5 bg-white h-16 w-16  rounded-xl transition-all duration-500 z-50">
                {{ item }}

                <label v-if="item==rating" class=" block text-xs">
                    Seçildi
                </label>
            </button>
        </div>

        <button type="submit"  :disabled="load" class="bg-[#a70431] px-5 py-3  mt-10 float-right font-bold text-lg text-white rounded-md ">
            <span v-if="!load">
                Anketi Tamamla
            </span>
            <span v-else>
                <i class="fas fa-spinner  fa-spin"></i>
                Tamamlanıyor
            </span>
        </button>
    </form>
</template>

<script>
import axios from 'axios'
export default {
    name: 'ratingPage',
    data() {
        return {
            rating: 0,
            load:false
        }
    },
    methods: {
        save() {
            if (this.rating > 0) {
                this.load=true;
                axios.post('https://bunudagorduk.com/save', {
                    fullname: this.$store.state.fullname,
                    email: this.$store.state.email,
                    company: this.$store.state.company,
                    position: this.$store.state.position,
                    questionAnswers: JSON.stringify(this.$store.state.questionAnswers),
                    score: this.rating
                },{
                    headers:{
                        Authorization:'bareer esa6T68bG4fPueJfqR6Z20'
                    }
                }).then(() => {
                    this.$emit('nextStep', true);
                })
                .catch(err => {
                    console.log(err);
                    alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GÖNDERİLEMEDİ LÜTFEN DAHA SONRA TEKRAR DENEYİNİZ")
                })
                .finally(()=>{
                    this.load=false;
                })
            } else {
                alert("Lütfen bir puan seçiniz !")
            }
        }
    }
}
</script>