import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fullname:'',
    email:'',
    company:'',
    position:'',
    questionAnswers:[],
    

    questionList:[]
  },
  getters: {
  },
  mutations: {
    setQuestionList(state,val){
      state.questionList=val;
    },
    setGeneralInformation(state,val){
      state.fullname=val.fullname;
      state.email=val.email;
      state.company=val.company;
      state.position=val.position;
    },
    setAnswerList(state,val){
      state.questionAnswers=val;
    }
  },
  actions: {
  },
  modules: {
  }
})
