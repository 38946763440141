<template>
    <div  class="bg-gray-200 px-10  py-10 min-h-screen w-full text-center">
        <i class="fas fa-check  fa-6x text-green-600"></i>
        <h4 class="text-xl font-bold mt-10">ANKET BAŞARI İLE TAMAMLANDI</h4>
        <p class="my-5 text-xs">Başarıyla tamamlanan anket için, verdiğiniz bilgilere olan değerli katkılarınızdan dolayı sizlere teşekkür ederiz. İlgili sorulara özenle cevap vererek bize zamanınızı ayırdığınız için takdir ediyoruz. Sizlerin görüşleri, çalışmamızı daha da ileriye taşımamızda büyük önem taşıyor. Bu nedenle, sağladığınız bilgileri dikkate alarak daha iyi hizmet sunma ve ihtiyaçlarınıza daha uygun çözümler üretme fırsatına sahip olacağız. Katılımınız ve desteğiniz için bir kez daha teşekkür ederiz.</p>

        <button @click="$parent.step=1" type="submit" class="bg-[#a70431] px-5 py-3 mt-6  font-bold text-sm text-white rounded-md">
            Başa Dön
        </button>
    </div>
</template>
<script>

export default {
    name: 'thanksItem',
}

</script>