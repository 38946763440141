<template>
      <form @submit.prevent="save" class="bg-gray-200 px-10  py-10 min-h-screen w-full">
        <h2 class="text-xl  w-8/12 text-left mb-8 font-bold pr-4">
            {{ activeItem.question }}
          </h2>
          <answerItem :id="item.id" :text="item.answer" v-for="item in activeItem.answers" :key="item.id"  
          @change="answer=>activeItem.active=answer"
          
          />
          <button type="submit" class="bg-[#a70431] px-6 py-3 mt-6 float-right font-bold text-lg text-white rounded-md">
            Sonraki
          </button>
      </form>
</template>
<script>
import answerItem from './components/answer.vue';
import axios from 'axios'
export default{
    name:'questionsItem',
    components:{
      answerItem
    },
    data(){
      return {
        List:[],
        load:false,
        stepIndex:0
      }
    },
    methods:{
      getAll(){

        if(this.$store.state.questionList.length>0) this.List=this.$store.state.questionList;
        else{
          axios.get("https://bunudagorduk.com/get/all",{
            headers:{
              Authorization:'bareer esa6T68bG4fPueJfqR6Z20'
            }
          }).then(res=>{
            this.List=res.data.data;
            this.$store.commit('setQuestionList',res.data.data)
          })
          .catch(err=>{
            console.log(err);
            alert("İŞLEM BAŞARISIZ OLDU");
          })
          .finally(()=>{
            this.load=false;
          })
        }

      },
      save(){
        if(this.activeItem.active>0){

          if(this.stepIndex+1==this.List.length){
            this.$store.commit('setAnswerList',this.List);
            this.$emit('nextStep',true);
          }else{
            this.stepIndex++;
          }
        }else{
          alert("Lütfen herhangi bir şıkkı seçin !")
        }

      }
    },
    computed:{
      activeItem(){
        return this.List.length>0 ? this.List[this.stepIndex] : {id:'',question:'',answers:[]};  
      }
    },
    created(){
      this.getAll();
    }



}
</script>