<template>
    <form @submit.prevent="save" class="bg-gray-200 px-10  py-10 min-h-screen w-full">
        <h4 class="text-3xl font-bold">KİŞİSEL BİLGİLER</h4>
      
        <p class="my-5">Lütfen tüm bilgileri eksiksiz ve doğru olucak şekilde doldurunuz !</p>

        <input type="text" v-model="fullname" minlength="2" required maxlength="100"
            class="px-4   w-full py-2  rounded-lg   bg-white  border" placeholder="Ad Soyad">

        <input v-model="company" type="text" minlength="2" required maxlength="255"
            class="px-4  w-full py-2  rounded-lg mt-4  bg-white  border" placeholder="Firma Adı">

        <input v-model="position" type="text" minlength="2" required maxlength="150"
            class="px-4 w-full py-2  rounded-lg mt-4  bg-white  border" placeholder="Pozisyon / Görev">
        <input type="email" v-model="email" required class="px-4  w-full py-2  rounded-lg mt-4  bg-white   border"
            placeholder="E-posta Adresi">
            
            <div class="flex items-center justify-between mt-10">
            
            <div class="flex items-center">
                <input required class="mr-2" v-model="isKvkk" type="checkbox">
                <a target="_blank" href="/kvkk">(KVKK) Sözleşmesini kabul ediyorum</a> 
            </div>
            <button type="submit" class="bg-[#a70431] px-6 py-3  float-right font-bold text-lg text-white rounded-md">
                Sonraki
            </button>
        </div>
    </form>
</template>
<script>
export default {
    name: 'homePage',
    data() {
        return {
            fullname: '',
            company: '',
            position: '',
            email: '',
            isKvkk:false
        }
    },
    methods: {
        save() {
            this.$store.commit('setGeneralInformation',{
                fullname:this.fullname,
                company:this.company,
                position:this.position,
                email:this.email
            })
            this.$emit('nextStep', true);
        }
    }
}

</script>