<template>
  <div id="app">
    <nav>
   
    </nav>
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  },
  // ...diğer bileşen seçenekleri ve metotlar...
}
</script>